import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const testimonialBlocks = document.querySelectorAll(
    '.testimonials-carousel-block'
  );

  if (0 < testimonialBlocks.length) {
    testimonialBlocks.forEach((block) => {
      const carousel = block.querySelector('.testimonials-carousel__carousel');
      let carouselArgs = {
        slidesPerView: 1.3,
        spaceBetween: 24,
        loop: false,
        watchOverflow: true,

        scrollbar: {
          el: carousel.querySelector('.swiper-scrollbar'),
          draggable: true,
        },

        navigation: {
          prevEl: carousel.querySelector('.swiper-button-prev'),
          nextEl: carousel.querySelector('.swiper-button-next'),
        },

        breakpoints: {
          540: {
            slidesPerView: 2.4,
          },
          920: {
            slidesPerView: 3.3,
          },
          1400: {
            slidesPerView: 3.8,
          },
        },
      };

      if (block.classList.contains('is-style-program')) {
        carouselArgs = {
          slidesPerView: 1.3,
          spaceBetween: 24,
          loop: false,
          watchOverflow: true,

          scrollbar: {
            el: carousel.querySelector('.swiper-scrollbar'),
            draggable: true,
          },

          navigation: {
            prevEl: carousel.querySelector('.swiper-button-prev'),
            nextEl: carousel.querySelector('.swiper-button-next'),
          },

          breakpoints: {
            768: {
              slidesPerView: 2.6,
            },
            1400: {
              slidesPerView: 5.05,
            },
          },
        };
      }

      new Swiper(carousel, carouselArgs);
    });
  }
});
